.rf-drag-icon {
  width: 3.4rem;
  height: 3.4rem;
  cursor: move;
  background: url(./drag.svg?inline) no-repeat center center;
  background-size: 3.4rem;

  &:hover {
    background-image: url(./drag_hover.svg?inline);
  }

  &:active {
    background-image: url(./drag_active.svg?inline);
  }
}
