.rf-search-bar {
  display: flex;
  margin-top: 1.5rem;
  height: 1.7rem;
  width: calc(100% - 110rem);
  position: relative;

  .rf-autocomplete {
    margin-left: 1rem;
    height: 3rem;
  }

  .rf-input,
  .rf-input__field {
    width: 100%;
  }

  .rf-input__field {
    font-size: 1.8rem;
    font-weight: 300;
    text-align: left;
    color: #999b9f;
    background: transparent;
    min-height: 0;
    border: 0;
    padding: 0;
  }

  .rf-input__container {
    width: 100%;
  }

  .rf-button-search {
    width: 1.8rem;
    height: 1.8rem;
    min-width: 1.8rem;
    min-height: 1.8rem;
    padding: 0;
    background: url('./images/search_normal.svg?inline') no-repeat center center;
    background-size: 1.684rem;
    margin-top: 0.8rem;
  }

  &_active .rf-button-search,
  .rf-button-search:hover {
    background-image: url('./images/search_active.svg?inline');
  }

  .rf-loader {
    position: absolute;
    right: 0;
  }

  .rf-close-button {
    svg {
      position: absolute;
      top: 1rem;
      height: 2rem;
      width: 2rem;
    }
  }

  &__info {
    margin-top: 0.6rem;
  }
}