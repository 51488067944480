@mixin maneuver-direction-icon {
  color: #333942;
  position: absolute;
  width: 1.4rem;
  height: 1.8rem;
  background-repeat: no-repeat;
  left: 2.5rem;
  top: 50%;
}

@mixin maneuver-transport-icon {
  color: #333942;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-size: 1.4rem;
}
