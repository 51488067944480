.rf-inspect-link-layers {
  &__column {
    position: relative;
    display: flex;
    margin: 1rem 0;
    border: 1px solid #b9bbc4;
    padding: 0.5rem;

    .rf-input_readonly {
      flex-grow: 1;
      width: 0;
      margin-right: 0.5rem;

      .rf-input__field {
        display: flex;
        align-items: center;
        padding-left: calc(var(--lui-padding-y) + 3px);
      }
    }
    .rf-input {
      flex-grow: 1;
      width: 0;
      margin-left: 0.5rem;
    }

    .rf-close-button {
      position: absolute;
      top: -0.3rem;
      right: 0;
    }
  }
  
  .rf-button-request {
    position: absolute;
    right: 0;
  }
}
