.rf-feature-row {
  margin-bottom: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;

  .rf-select {
    width: 60%;

    flex: auto;
    &__field {
      width: 100%;
    }
  }

  .rf-checkbox {
    width: 40%;
  }
}
