@import '../../../utils/styles/variables';

.rf-history-list {
  font-size: 1.6rem;
  word-break: break-all;
  list-style: none;
  width: 100%;
  height: 19.5rem;
  transition: height 0.25s ease-out;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  color: #96989e;

  &_hidden {
    height: 0;
  }

  &__item {
    transition: background-color 0.2s ease-out;
    cursor: pointer;
    padding: 0.9rem 1rem;
    display: flex;
    align-items: center;

    &_active {
      background-color: $light-grey;
      pointer-events: none;
    }

    &:hover {
      background-color: $light-grey;
    }

    &-status {
      margin: 0 1.5rem;
      white-space: nowrap;

      .fa {
        font-weight: bold;

        &-exclamation-circle {
          color: $error-color;
        }

        &-check-circle {
          color: $green-button-color;
        }
      }
    }

    &-number {
      white-space: nowrap;
    }
  }
}
