.rf-checkbox-list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  column-count: 2;
  display: flex;
  flex-wrap: wrap;

  &__item {
    --lui-control-height: auto;
    width: 50%;
    page-break-inside: avoid;
    break-inside: avoid;
    display: block;

    .rf-checkbox {
      height: auto;
    }

    &_unknown {
      border: orange 2px solid;
    }
  }
}
