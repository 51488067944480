.rf-anchor-list {
  .rf-expand-form-row__container {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  &__item {
    &__label {
      font-size: 2rem;
      background-color: #ffffff;
      padding: 1rem;
      cursor: pointer;
      border-bottom: 0.1rem solid #ddd;
      padding-bottom: 2rem;
      font-size: 1.5rem;

      &_selected {
        background-color: rgba(72, 218, 208, 0.1);
      }

      &:hover {
        background-color: rgba(72, 218, 208, 0.5);
      }
    }
  }
}
