@import '../../utils/styles/variables';

.rf-settings-error {
  margin: 1rem 0;
  border: 2px $error-color solid;
  border-radius: 0.5rem;
  padding: 1rem;
  line-height: normal;

  &__message {
    color: $error-color;
  }

  h4 {
    color: #ffffff;
    font-size: 2rem;
  }
}
