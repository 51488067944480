.rf-api-url-textarea {
  position: fixed;
  background-color: #e5e7ea;
  background-clip: border-box;
  border: solid 1px rgba(15, 22, 33, 0.25);
  border-radius: var(--lui-textfield-border-radius);
  max-height: calc(100% - 12rem);
  overflow-y: auto;

  &__scrollable {
    position: relative;
  }

  &__content {
    padding: var(--lui-padding-y) calc(var(--lui-padding-y) + 3px);
    padding-right: 20rem;
    caret-color: var(--lui-field-caret-color);

    color: var(--lui-field-text-color);
    font-family: var(--lui-font-family);
    font-size: var(--lui-font-size);
    line-height: var(--lui-line-height);
    min-height: var(--lui-control-height);
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &__host {
      color: #00918e;
      font-size: 1.9rem;
    }

    &__param {
      font-size: 1.8rem;

      &_multiline {
        padding-left: 2rem;
      }
    }
  }

  &__toggle {
    position: fixed;
    padding-bottom: var(--lui-padding-y);
    padding-right: var(--lui-padding-y);
    z-index: 1;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .rf-toggle-button {
      display: flex;
      margin-bottom: 0.5rem;
    }
  }
}
