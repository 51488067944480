.leaflet-marker-icon {
  border-radius: 50%;
}

.leaflet-marker-icon-rect {
  border-radius: 0%;
}

.rf-polygon__marker {
  background-color: gray;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 12px;
  height: 12px;
  border: 1px black solid;
}

.rf-polygon__here-marker {
  display: flex;
  background-color: gray;
  border: 1px black solid;
  margin-top: -5px !important;
  margin-left: -5px !important;
  justify-content: center;
  line-height: 11px;
  width: 12px;
}

.rf-polygon__marker-draggable {
  background-color: white;
}

.rf-polygon__polygon-marker-draggable {
  background-color: lightblue;
}

.rf-polygon__middle-marker {
  border-radius: 50%;
}

.leaflet-marker-draggable {
  z-index: 1000 !important
}