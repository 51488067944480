@mixin button {
  transition: background-color 0.15s linear, max-width 0.15s ease-in-out,
    padding 0.15s ease-in-out, margin 0.15s ease-in-out;
  background-color: #00afaa;
  border: 0;
  border-radius: 0.2rem;
  color: #fff;
  cursor: pointer;
  font-size: 1.3rem;
  height: 4rem;
  line-height: 4rem;
  min-width: 0.7rem;
  outline: 0;
  white-space: nowrap;
  margin: 0 auto;
  vertical-align: middle;
  padding: 0 2rem;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: #00b4e5;
  }

  &:active {
    background-color: #00aad9;
  }

  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
}
