@import '../../../utils/styles/variables';

.rf-filter-panel-wrapper {
  height: 100%;
}

.rf-filter-panel {
  width: $left-sidebar-width;
  left: -$left-sidebar-width;
  transition: left ease 0.5s;
  

  &_shown {
    left: 0;
  }

  .rf-main__content-no_icons_panel & {
    width: ($left-sidebar-width - $icons-bar-width);
  }

  .rf-tabs {
    margin-top: 1rem;
    &__content {
      padding: 0 1rem;
    }
  }

  &__toggler {
    transition: left ease 0.5s, width ease 0.5s;
    position: absolute;
    top: 1.5rem;
    left: $left-sidebar-width;
    width: 2rem;
    height: 4rem;
    cursor: pointer;
    background-color: #f5f5f5;
    text-align: center;
    box-shadow: 0.1rem 0 0.1rem 0rem rgba(0, 0, 0, 0.4);
    font-size: 1.2rem;
    // min-width: 4rem;
    color: #666;
    box-sizing: content-box;
    display: block;
    z-index: 1;

    &:after {
      transition: left ease 0.5s;
      content: '';
      position: absolute;
      top: 1.2rem;
      left: 0.5rem;
      width: 1rem;
      height: 1.6rem;
      background-image: url('./images/hide_response.svg?inline');
      background-size: 1rem 1.6rem;
    }

    &:hover {
      width: 4rem;
      &:after {
        transition: left ease 0.5s;
        background-image: url('./images/hide_response_hover.svg?inline');
        left: 1.5rem;
      }
    }

    &_visible {
      display: block;
    }
  }

  &_fadeout {
    left: 0;
  }

  &_fadeout {
    &:after {
      background-image: url('./images/show_response.svg?inline');
      background-size: 1rem 1.6rem;
    }

    &:hover {
      &:after {
        background-image: url('./images/show_response_hover.svg?inline');
      }
    }
  }

  h3,
  .rf-h3 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
  }
}
