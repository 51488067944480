.rf-tooltip {
  .rc-tooltip {
    &-inner {
      min-height: initial;
      background-color: #071028;
    }
  }
  &.rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: #071028;
  }
  &.rc-tooltip-placement-left .rc-tooltip-arrow {
    border-left-color: #071028;
  }
  &.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: #071028;
  }
  &.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: #071028;
  }

  &__children {
    display: flex;
  }
}
