.rf-mode {
  &.rf-grey-box {
    overflow: visible !important;
  }

  h4 {
    padding: 1.5rem 0;
  }

  .rf-expand-form-row {
    border-width: 0;
  }

  .rf-features-group {
    padding: 2rem 0 0 0;
  }

  .rf-bicycle-mode {
    &__title {
      color: #b9bbc4;
      margin: 0;
      font-weight: normal;
    }

    &__open-in-new-tab {
      margin-top: 2rem;
    }
  }
}
