.rf-configs-modal {
  &__textarea {
    textarea {
      width: calc(100vw - 300px);
      height: calc(100vh - 300px);
      background: white;
      color: black;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    lui-button {
      display: flex;
    }
  }
}
