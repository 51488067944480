.rf-route-matching {
  h2 {
    font-size: 1.5rem;
  }

  &__slider {
    margin: 1rem;

    .rc-slider-track {
      background-color: rgba(0, 175, 170, 0.7);
    }

    .rc-slider-dot-active {
      border-color: rgb(0, 175, 170);
    }

    .rc-slider-handle {
      border: solid 2px rgb(0, 175, 170);
    }
  }
  .rf-radiogroup__caption,
  .rf-radiooption span {
    color: #6a6d74;
  }
}

.rf-warning-message {
  &:hover {
    cursor: pointer;
  }
}