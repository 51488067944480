.rf-here-map {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: grey;
  z-index: 0;
}
