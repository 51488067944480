.rf-color-picker {
  display: flex;

  .rf-input {
    flex-grow: 2;
  }

  &__color {
    flex-grow: 1;
    margin-left: 1rem;
    height: 4rem;
    align-self: flex-end;
    cursor: pointer;

    .sketch-picker {
      position: absolute;
    }
  }

  .sketch-picker {
    z-index: 1;
  }
}
