.rf-result-storage-modal {
  h1 {
    width: 50vw;
  }

  textarea.rf-textarea__field {
    background-color: #41474f;
    border: #272d37 solid 1px;
  }

  &__action-btns {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
