.rf-expand-form-row-container {
  position: relative;
  
  .rf-doc-link {
    position: absolute;
    top: 10px;
    right: 40px;
  }
  
}
  