.rf-polygon__marker {
  position: absolute;
  top: -2px;
}

.rf-polygon__here-marker {
  display: flex;
  background-color: white;
  border: 1px black solid;
  margin-top: -5px !important;
  margin-left: -5px !important;
  justify-content: center;
  line-height: 11px;
  width: 12px;
}
