@import '../../../../utils/styles/variables';

.rf-sidebar {
  float: left;
  height: 100%;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
  transition: width ease 0.5s;

  .rf-grey-box {
    background: #434750;
    padding: 1rem 1rem;
    margin: 0.5rem 0;
    overflow: visible;
    // border:1px solid #464D59;
    border:1px solid rgba(195,207,223,.2);

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      background: #64686f;
      position: relative;
      bottom: -3rem;
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }
  }

  &__error {
    margin: 0 1.7rem;
  }

  &__header {
    bottom: 0;
    font-size: 1.6rem;
    left: 0;
    padding: 1rem 1.9rem 0.6rem 1.9rem;
    position: relative;
    right: 0;
    color: #333942;
    margin: 0;

    &_error {
      background-color: #f4d2d7;
      color: #c41c33;
      border-left: solid 0.4rem #c41c33;
      padding: 2rem 3rem;
      margin-top: 1.7rem;
    }
  }

  h4 {
    font-size: 1.3rem;
    color: #6a6d74;
    text-transform: uppercase;
    margin: 1rem 1rem 0.6rem 1rem;
    padding: 0;
  }

  &__inner {
    vertical-align: top;
    padding: 1.5rem 1rem;
    display: inline-block;
    box-sizing: border-box;
    background-color: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    & > .rf-grey-box {
      background: #434750;
      padding: 1rem 1rem;
      margin: 1rem -1rem 0.1rem -1rem;
      overflow: hidden;
    }

    .rf-grey-box + .rf-grey-box {
      margin-top: 0;
    }

    .rf-icons-bar {
      position: absolute;
      left: 0;
      top: 0;
      padding: 5rem 0 0 0;
      margin: 0;
      background: #272d37;
      height: calc(100% - 5rem);
      width: 7rem;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: content-box;
    }
  }

  .rf-form-content {
    height: 100%;
  }

  &.rf-filter-panel &__inner {
    background-color: #383c45;
    position: relative;

    .rf-main__content-no_icons_panel & {
      padding-left: 1rem;
    }
  }

  &__footer {
    margin-bottom: 1rem;
  }

  hr {
    border: 0;
    height: 0.1rem;
    background: #64686f;
  }
}
