.rf-maneuver-visualization-form-view {
  display: flex;
  flex-direction: column;

  .rf-button {
    margin-top: 1rem;
    margin-right: 0;
    justify-self: flex-end;
  }
  
  .rf-file-input {
    padding: 0.7rem;
  }
}
