.rf-charging-stations {
  cursor: pointer;
  border-bottom: 0.1rem solid #ddd;
  padding-bottom: 2rem;
  font-size: 1.5rem;

  &__link {
    font-size: 2rem;
    color: #b9bbc4;

    a {
      color: #b9bbc4;
      font-style: italic;
    }
  }

  &__points {
    padding-bottom: 1rem;
    color: orange;
    font-size: 2rem;
  }

  &__tabs {
    margin: 1rem 0;
  }

  &__slider {
    margin: 1rem;

    .rc-slider-track {
      background-color: rgba(0, 175, 170, 0.7);
    }

    .rc-slider-dot-active {
      border-color: rgb(0, 175, 170);
    }

    .rc-slider-handle {
      border: solid 2px rgb(0, 175, 170);
    }
  }

  &__slider-text {
    margin: 1rem;
  }

  .rf-bounding-box {
    position: relative;

    .rf-close-button {
      position: absolute;
      right: 28px;
      top: 5px;
    }
  }

  &__inner {
    position: relative;
    padding: 2rem 1rem 0 2rem;
    line-height: 1.38;
  }

  &__content {
    line-height: normal;
    word-wrap: break-word;
    padding-bottom: 2rem;
  }

}

.rf-hovered-station-tooltip {
  position: fixed;
  z-index: 500;
  background-color: #383c45;
  border-radius: 1rem;

  &__station {
    border-bottom: 1px solid #b9bbc4;

    &:last-child {
      border-bottom: none;
    }

    .rf-form-row {
      margin: 1rem;
    }
  }
}

.rf-sidebar__inner .rf-charging-stations {
  padding: 0 5px 0 0px;
}

.rf-sidebar__inner .routeto {
  margin-top: 10px;
  padding:  0 0;
  width: 35%;
}

.rf-sidebar__inner .routebutton {
  margin-left: 2%;
}