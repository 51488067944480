.rf-transport-mode {
  margin: 0.8rem 0 0 0;

  .rf-transport-mode__title {
    color: #b9bbc4;
    font-size: 1.3rem;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    line-height: 3rem;
    margin-left: 0;

    &__value {
      font-weight: bold;
      color: #48dad0;

      .rf-close-button {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        right: -0.1rem;

        svg {
          position: absolute;
          top: 0.2rem;
          fill: #48dad0;
        }
      }
    }
  }

  &__items {
    display: flex;

    &__item {
      width: 3.4rem;
      cursor: pointer;
      margin-right: 5px;

      svg {
        fill: #9c9fa3;
        fill-rule: evenodd;
      }

      &:hover {
        svg {
          fill: #ffffff;
        }
      }

      &_active {
        svg {
          fill: #48dad0;
        }
      }
    }
  }
}
