.rf-via-fields {
  .rf-button {
    margin-top: 1rem;
  }
}

.rf-via-field-item {
  position: relative;

  .rf-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
