.rf-ols-consumption {
  border-bottom: none;

  .rf-optional {
    min-inline-size: auto;
  }
}

.rf-battery-fields {
  .rf-connector-types {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.rf-emsp-id {
  width: calc(63% - 3.95rem);
}

.rf-emsp-preference {
  width: calc(40% - 3.95rem);
}

.rf-emsp-id-empty {
  display: none;
}

.rf-entry-close-button {
  margin-top: 3.2rem;
  width: 2.2rem;
}