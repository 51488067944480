.tabbar{
  overflow:auto;
  white-space: nowrap;
  .tab{
    display: inline-block;
  }
  &::-webkit-scrollbar {
    height: 8px;
    background-color: #FFF; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #CCC; 
  }
}
.tab{
  cursor: default;
  user-select: none;
  white-space: nowrap;
  list-style-type: none;
  margin-right: -1px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  padding: 0 0.4rem 0.4rem 0.4rem;
  margin-left: -0.4rem;
}