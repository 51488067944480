.rf-raw-response {
  .rf-copy-button {
    span {
      width: 100%;

      lui-button {
        width: 100%;
      }
    }
  }

  --lui-text-color: black;
}

.toolTip-rawResponse {
  max-width: 500px;
}