.rf-algorithm {
  margin-left: 2rem;
}
.rf-result-legend__table {
  color: #696868;
  font-size: 1.4rem;
  margin: 1.5rem 0;
  width: 100%;

  &-header {
    text-align: center;
    border-bottom: 0.1rem solid #273142;
    line-height: 2.1rem;
  }

  &-row {
    line-height: 2.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      background-color: #e8e8e8;
    }

    &-timecol {
      text-align: right;
    }

    &-transportcol {
      text-align: left;
      padding: 0 0 0 1rem;

      .rf-transport-type-icon {
        margin-right: 0.3rem;
      }
    }

    &-active {
      background-color: #dcdcdc;
    }
  }
}
