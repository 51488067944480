@import '../../../utils/styles/variables';

.rf-map {
  .rf-pane {
    img {
      max-width: none !important;
      max-height: none !important;
    }
  }
}

$transition-time: 0.5s;
@mixin zoom-top {
  &__zoom-controls {
    top: 6rem;
  }
}

@mixin smaller-map-icons {
  &__zoom-controls {
    top: 6rem;
    .rf-layer-toggle {
      &:first-child {
        background-size: 1rem 1rem;
      }

      &:last-child {
        background-size: 1rem 0.3rem;
      }
    }
  }

  &__dm-toggle {
    bottom: 12.9rem;

    .rf-layer-toggle {
      background-size: 2.5rem 2.5rem;
    }
  }

  &__ror-toggle {
    bottom: 19.5rem;

    .rf-layer-toggle {
      background-size: 2.5rem 1.3rem;
    }
  }

  &__matchRouteConfig-toggle {
    bottom: 16.2rem;

    .rf-layer-toggle {
      background-size: 2rem 2rem;
    }
  }

  &__filter_by_power-toggle {
    bottom: 16.2rem;

    .rf-layer-toggle {
      background-size: 2rem 2rem;
    }
  }

  &__tile-toggle {
    bottom: 6.3rem;

    .rf-layer-toggle {
      background-size: 2.3rem 1.7rem;
    }
  }

  &__traffic-overlays {
    .rf-layer-toggle__label {
      font-size: 1.2rem;
    }
  }

  &__truck-icon {
    bottom: 9.6rem;

    .rf-layer-toggle {
      background-size: 2rem 2rem;
    }
  }

  &__adjust_viewport-icon {
    background-size: 2rem 1.75rem;
    width: 3rem;
    height: 3rem;
    bottom: 12.9rem;
  }

  &__tooltip {
    right: 4rem;
  }
}

@mixin vertical-map-toggles {
  &__dm-toggle {
    bottom: 3rem;
    right: 11.1rem;

    .rf-layer-toggle {
      background-size: 2.5rem 2.5rem;
    }
  }

  &__ror-toggle {
    bottom: 3rem;
    right: 18rem;
  }

  &__matchRouteConfig-toggle {
    bottom: 3rem;
    right: 14.5rem;
  }

  &__filter_by_power-toggle {
    bottom: 3rem;
    right: 14.5rem;
  }

  &__tile-toggle {
    bottom: 3rem;
    right: 4.1rem;

    .rf-layer-toggle {
      background-size: 2.3rem 1.7rem;
    }
  }

  &__truck-icon {
    bottom: 3rem;
    right: 7.6rem;

    .rf-layer-toggle {
      background-size: 2rem 2rem;
    }
  }

  &__ror-module {
    bottom: 3rem;
    right: 17.9rem;
  }

  &__matchRouteConfig-module {
    bottom: 3rem;
    right: 17.9rem;
  }

  &__power-module {
    bottom: 3rem;
    right: 17.9rem;
  }

  &__tiles-module {
    right: 7.6rem;
  }

  &__adjust_viewport-icon {
    background-size: 2rem 1.75rem;
    bottom: 3rem;
    right: 11.1rem;
  }
}

.rf-map {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: grey;
  z-index: 0;
  transition: width ease 0.5s, margin-left ease 0.5s, height ease 0.5s;

  .leaflet-container {
    width: 100%;
    height: 100%;

    .leaflet-top {
      top: 50%;
      transform: translate(0%, -50%);
      right: 0.5rem;
      left: unset;
    }
  }

  .rf-main__content-no_icons_panel & {
    width: calc(100% - #{$left-sidebar-width - $icons-bar-width});
    margin-left: ($left-sidebar-width - $icons-bar-width);
  }

  &__zoom-controls {
    position: absolute;
    top: calc(50% - 5.25rem);
    right: 0.6rem;
    transition: top $transition-time;

    .rf-layer-toggle {
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
    }

    .rf-tooltip__children {
      &:first-child {
        .rf-layer-toggle {
          background-image: url('./images/zoom_in.svg?inline');
          background-size: 2rem 2rem;
          margin-bottom: 0.5rem;
        }
      }

      &:last-child {
        .rf-layer-toggle {
          background-image: url('./images/zoom_out.svg?inline');
          background-size: 2rem 0.4rem;
        }
      }
    }
  }
  &__dm-toggle,
  &__ror-toggle,
  &__matchRouteConfig-toggle,
  &__tile-toggle,
  &__traffic-overlays,
  &__truck-icon,
  &__adjust_viewport-icon,
  &__filter_by_power-toggle {
    right: 0.6rem;
    transition: left, right $transition-time;
  }

  &__dm-toggle {
    position: absolute;
    bottom: 19.5rem;

    .rf-layer-toggle {
      background-image: url('./images/Ruler.svg?inline');
      background-size: 3.2rem 3.2rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__ror-toggle {
    position: absolute;
    bottom: 30.5rem;

    .rf-layer-toggle {
      background-image: url('./images/RoR.svg?inline');
      background-size: 3.2rem 1.8rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__matchRouteConfig-toggle {
    position: absolute;
    bottom: 25rem;

    .rf-layer-toggle {
      background-image: url('./images/settings.svg?inline');
      background-size: 2rem 2rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__filter_by_power-toggle {
    position: absolute;
    bottom: 25rem;

    .rf-layer-toggle {
      background-image: url('./images/power.svg?inline');
      background-size: 3.2rem 3.2rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__tile-toggle {
    position: absolute;
    bottom: 8.5rem;

    .rf-layer-toggle {
      background-image: url('./images/layers.svg?inline');
      background-size: 2.9rem 2.1rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__traffic-overlays {
    position: absolute;
    bottom: 3rem;

    .rf-layer-toggle__label {
      color: #272d37;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  &_collapsed-right {
    width: calc(100% - #{$right-sidebar-width});

    .rf-main__content-no_icons_panel & {
      width: calc(
        100% - #{$left-sidebar-width + $right-sidebar-width - $icons-bar-width}
      );
    }
  }

  &_collapsed-left {
    margin: 0 0 0 $left-sidebar-width;
    width: calc(100% - #{$left-sidebar-width});
  }

  &.rf-map_collapsed-right.rf-map_collapsed-left {
    width: calc(100% - #{$left-sidebar-width + $right-sidebar-width});
  }

  &.rf-map_no-api-url-bar {
    height: 99.999%;
  }

  &__truck-icon {
    position: absolute;
    bottom: 14rem;

    .rf-layer-toggle {
      background-image: url('./images/Truck.svg?inline');
      background-size: 3rem 3rem;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__adjust_viewport-icon {
    position: absolute;
    bottom: 19.5rem;
    background-image: url('./images/adjust_viewport.svg?inline');
    background-size: 3.2rem 2.8rem;
    background-position: center center;
    background-repeat: no-repeat;
    width: 5rem;
    height: 5rem;
    background-color: rgba(186, 188, 196, 0.7);
    cursor: pointer;
    border-radius: 0.5rem;
  }

  &__avoid-area-number {
    position: absolute;
    left: -1.6rem;
    top: 0;
    font-size: 1.4rem;
    width: 1.7rem;
    height: 1.7rem;
    line-height: 1.7rem;
    opacity: 0.5;
    background-color: #ec3e0e;
    text-align: center;
    color: #fff;
  }

  .H_ib_body {
    background-color: #071028;
    font-size: 1.4rem;

    .b-info-label {
      float: left;
    }
  }

  .route-handle {
    border: 0.3rem solid #fff;
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    position: absolute;
  }

  &__tooltip {
    position: absolute;
    right: 6.2rem;
    background-color: rgba(35, 39, 48, 0.9);
    border-radius: 1rem;
    max-height: 100%;
    overflow-y: scroll;

    &__toggler {
      position: absolute;
      top: 1.1rem;
      right: 1rem;
    }

    h2,
    .rf-input__caption,
    .rf-select__caption,
    .rf-radiogroup__caption,
    .rf-radiooption__caption {
      color: #fff;
    }

    h2 {
      font-size: 1.6rem;
      font-weight: 300;
    }

    .rf-input__caption,
    .rf-select__caption {
      font-size: 1.3rem;
      color: #b9bbc4;
      letter-spacing: 0.03rem;
      text-transform: uppercase;
      line-height: 1.5;
      min-height: 3rem;
      vertical-align: top;
    }

    input {
      width: 100%;
      font-size: 1.3rem;
      background-color: #272d37;
      height: 3rem;
      padding: 0 1rem;
      color: #babcc4;
      border: 0;
    }

    .rf-input {
      &__field {
        transition: none;
      }
      &__container {
        height: auto;
      }
    }

    .rf-select__field {
      float: none;
      border-radius: 0.2rem;
      background-color: #272d37;
      color: #babcc4;
      border: 0;
    }
  }

  &__matchRouteConfig-module {
    padding: 0 1rem 1rem 1rem;
    bottom: 16.4rem;
    width: 22rem;
    height: 14rem;

    h2 {
      margin-top: 2rem;
    }

    .rf-form-row {
      display: flex;
      align-items: center;

      span {
        font-size: 1.5rem;
        color: #b9bbc4;
        padding-right: 1rem;
      }
    }
  }

  &__power-module {
    padding: 1rem 1rem 1rem 1rem;
    bottom: 9.4rem;
    width: 23rem;
  }

  &__tiles-module,
  &__to-module {
    z-index: 1000;
    padding: 0 1rem 1rem 1rem;
    bottom: 3rem;
    width: 35rem;
  }

  &__openin-module, &__avoidexclude-module {
    position: absolute;
    z-index: 1000;
    padding: 0.5rem 1rem 1rem 1rem;
    top: 50%;
    width: 35rem;
    right: 50%;
    left: 50%;
    transform: translate(-17.5rem, -18rem);
    overflow-y: hidden;
    background-color: rgba(35, 39, 48, 0.9);
    border-radius: 1rem;
    max-height: 100%;
  }

  &__openin-module h2, &__avoidexclude-module h2 {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
  }

  &__openin-tabs input, &__avoidexclude-tabs input {
    cursor: pointer;
  }

  @media screen and (max-height: 77.7rem) {
    @include zoom-top();
  }

  @media screen and (max-height: 58.5rem) {
    @include smaller-map-icons();
  }

  @media screen and (max-height: 45.6rem) {
    @include vertical-map-toggles();
  }
}

#map {
  height: 100%;
}

.rf-main__content_history {
  .rf-map {
    @media screen and (max-height: 96.5rem) {
      @include zoom-top();
    }

    @media screen and (max-height: 77.5rem) {
      @include smaller-map-icons();
    }

    @media screen and (max-height: 64.8rem) {
      @include vertical-map-toggles();
    }
  }
}
