.rf-maneuvers {
  cursor: pointer;
  border-bottom: 0.1rem solid #ddd;
  padding-bottom: 2rem;
  font-size: 1.5rem;

  &-empty {
    color: red;
    font-size: 1.5rem;
    padding-left: 2rem;
  }

  &_selected {
    background-color: rgba(72, 218, 208, 0.1);
  }

  &_disabled {
    cursor: not-allowed;
  }

  &:last-child {
    border-bottom: 0.1rem solid #ddd;
    margin-bottom: 1.9rem;
  }

  &__inner {
    position: relative;
    padding: 2rem 1rem 0 2rem;
    line-height: 1.38;
  }

  &__content {
    line-height: normal;
    word-wrap: break-word;
  }

  &__details {
    padding: 1rem 1rem 0 6.5rem;
    font-size: 1.3rem;

    & > * {
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}

.rf-maneuvers__details .rf-tooltip__children {
  color: #888;
  margin-left: 10px;
}

.tooltip-notices {
  max-width: 500px;
}