.rf-exclude-states {
  &__item {
    display: flex;
    margin: 1rem;

    .rf-input {
      flex-grow: 1;
    }

    .rf-close-button {
      margin-left: 1rem;
    }
    .rf-button {
      margin-left: 1rem;
    }
  }

  &__btn {
    margin: 1rem;
  }
}
