.rf-share-container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &__icon {
    font-size: 2.7rem;
    color: rgb(153, 155, 160);
    width: 7.8rem;
    height: 2.2rem;
    padding: 0 3rem;
    background: url(./images/share.svg?inline) no-repeat center center;
    background-size: 1.818rem 2rem;

    &_active {
      background-image: url(./images/share_active.svg?inline);
    }
  }

  &:hover &__icon {
    background-image: url(./images/share_hover.svg?inline);
  }

  &__menu {
    position: absolute;
    right: 0.5rem;
    top: 5rem;
    width: 50rem;
    padding: 1.8rem;
    background: rgb(56, 60, 69);
    z-index: 5;

    &__share-button {
      background-image: url('./images/copy.svg?inline');
      background-size: 2.4rem 2.4rem;
      background-repeat: no-repeat;
      background-position: 0.8rem 0.8rem;
      padding: 1rem 0 1rem 3.5rem;
      position: relative;
      left: -1rem;

      &:hover {
        background-color: transparent;
      }
    }

    h3 {
      margin: 0.2rem 0 1.5rem 0;
      font-size: 1.6rem;
      font-weight: 300;
      color: white;
    }

    h4 {
      color: #b9bbc4;
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      margin: 1.7rem 0 1rem 0;
    }

    textarea {
      padding: 0.8rem;
      border: 0;
      width: 46.2rem;
      height: 18.1rem;
      background: rgb(39, 45, 55);
      color: #babcc4;
      font-size: 1.6rem;
      font-weight: 300;
      font-style: italic;
      word-break: break-all;
      resize: none;
    }

    lui-button {
      margin-top: 1.6rem;
    }

    .rf-tooltip__children {
      flex-direction: column;
    }
  }
}
