.rf-hotisontal-line {
  width: 100%;
  border-top: 0.1rem #9c9fa3 solid;
  margin-top: 2rem;
}

.rf-advanced-params {
  position: relative;

  .rf-tooltip__children {
    position: absolute;
    right: 3rem;
    top: 0.2rem;
    z-index: 1;

    svg {
      height: 3rem;
      fill: #ffbd09;
    }
  }
  .rf-expand-form-row {
    border-bottom: 0;

    .rf-expand-form-row__label {
      &:hover {
        color: #48dad0;
      }

      &-text {
        font-style: normal;
        border-bottom: 0.1rem dashed #696868;
        font-size: large;
        letter-spacing: 0.03rem;
        text-align: left;
        text-transform: none;
      }
    }
    .rf-expand-form-row__container {
      .rf-advanced-sub-params {
        border-bottom: 0;
    
        .rf-expand-form-row__label {
          &:hover {
            color: #48dad0;
          }
    
          &-text {
            margin-left: 20px;
            font-style: italic;
            border-bottom: 0.1rem dashed #696868;
            font-size: 1.6rem;
            letter-spacing: 0.03rem;
            text-align: left;
            text-transform: none;
          }
        }
      }
    }
  }
  .rf-grey-box {
    border-bottom: 1px solid rgba(195, 207, 223, 0.2);
  }
}