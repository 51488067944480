.rf-datetime-field {
  &__timezone {
    display: flex;
    flex-direction: row;

    & > * {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }

  &__checkbox {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    margin-left: 1.9rem;
    vertical-align: bottom;

    .rf-checkbox__field {
      float: left;
    }
    .rf-checkbox__caption {
      text-transform: none;
      font-size: 1.6rem;
      line-height: 4rem;
    }
  }

  .rf-form-row {
    margin: 0.5rem 0;
  }

  .rf-select__field {
    width: 100%;
  }

  .rf-datetime {
    float: left;
    width: 100%;

    .rf-input {
      width: 100%;
    }
  }
}
