html {
  font-size: 1vmin;
}

body {
  overflow-x: hidden;
}

#root {
  height: 100%;
}

.rf-main {
  height: 100%;
  overflow: hidden;

  &__content {
    height: calc(100% - 17rem);
    transition: height 0.25s ease-out;
    position: relative;

    &_history {
      height: calc(100% - 36.1rem);
    }

    &_no-api-url-bar {
      height: calc(100% - 11rem);
    }
  }
}

.rf-clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

/*Scrollbar rules*/
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 10rem;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 10rem;
}
::-webkit-scrollbar-thumb:vertical:active,
::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 10rem;
}
