@import '../../_mixins/index';
@import '../../../utils/styles/variables';

.rf-api-url {
  padding: 0 1rem;
  height: 6rem;
  overflow: hidden;
  background-color: $light-grey;
  display: flex;
  border-top: 0.1rem solid #4f545c;
  position: relative;
  z-index: 5;

  &__form {
    flex: 1;
    padding: 1rem 1rem;
    display: flex;

    &__btn {
      @include button;
      color: white;
      background-color: $green-button-color;
      border-radius: 0.2rem;
      margin-left: 1rem;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      height: 4rem;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
  }

  &__history-btn {
    @include button;
    margin: 1rem 0;
    height: 4rem;
    line-height: 4rem;
    background-color: $light-grey-button-color;
    border: 0.1rem solid $green-button-color;
    border-radius: 0.2rem;
    color: $green-button-color;
    text-transform: uppercase;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    margin-left: 1rem;
    font-size: 1.3rem;

    &:hover {
      background-color: #e4e6e9;
    }

    &__counter {
      height: 1.6rem;
      border-radius: 0.2rem;
      background-color: #4d5258;
      padding: 0 0.5rem;
      margin-left: 0.8rem;
      color: #fff;
      font-size: 1.3rem;
      font-style: normal;
    }
  }

  &__modal-button-group {
    margin-top: 2rem;
  }
}

.rf-parsing-errors {
  width: 39.4rem;
  position: fixed;
  margin-top: 10rem;
  margin-left: -19.7rem;
  left: 50%;
  bottom: auto;
  height: auto;

  &__buttons-container {
    float: right;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .rf-modal__header {
    padding: 0;
    margin: 1.5rem 1.5rem 0 1.5rem;
    line-height: 1.25;
    font-size: 1.6rem;
    font-weight: 500;
    color: #c41c33;
  }

  .rf-modal__container {
    padding: 0 1.5rem;
    margin-top: 0;
    width: auto;

    lui-button-group {
      margin-top: 1rem;
    }
  }
}
