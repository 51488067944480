.rf-segment-speed-cap {
  h4 {
    padding: 1.5rem 0;
  }

  .rf-expand-form-row {
    border-width: 0;
  }
}

.rf-speedcap-segmentid {
  width: calc(45% - 0.95rem)
}

.rf-speedcap-direction {
  width: calc(22% - 1.95rem);
  margin-left: 0.9rem;
}

.rf-speedcap-speed {
  width: calc(22%);
  padding-left: 1.95rem;
}

.rf-speedcap-segment-close-button {
  margin-top: 3.2rem;
  width: 2.2rem;
}