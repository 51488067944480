.rf-map__ror-module {
  z-index: 1000;
  padding: 0 1rem 1rem 1rem;
  bottom: 16.4rem;
  width: 41rem;

  .rc-slider {
    width: calc(100% - 1rem);
  }

  h2 {
    margin-top: 1.2rem;
  }

  &_electric {
    .rf-form-row {
      display: flex;
      align-items: center;

      .rf-checkbox {
        width: 22rem;
      }

      label {
        width: 10rem;
      }

      & > span {
        padding-left: 2rem;
        font-size: 1.6rem;
        font-weight: 300;
        color: #fff;
      }
    }

    lui-textfield {
      overflow: hidden;
    }

    .rf-toggle-button {
      position: absolute;
      top: 1.1rem;
      right: 1rem;
    }
  }
}
