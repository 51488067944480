@import '../../../_mixins/index';
@import '../../../../utils/styles/variables';

.rf-multi-select {
  font-size: 1.6rem;
  &__caption {
    @include form-caption;
    width: 100%;
  }
}

// Override library styles
.is-focused:not(.is-open) > .Select-control {
  border-color: $form-border-color;
  box-shadow: none;
}

.Select--multi {
  .Select-control {
    border-radius: 0.2rem;
    font-size: 1.6rem;
    background-color: #272d37;
    color: #babcc4;
    border: 0;
    height: 4rem;

    .Select-placeholder {
      line-height: 4rem;
    }
  }

  .Select-menu-outer {
    z-index: 91;
    .Select-option {
      background-color: #272d37;
      color: #babcc4;
      font-size: 1.6rem;

      &:hover {
        background: #1e90ff;
        color: #fff;
      }
    }
  }

  .Select-value {
    background-color: $form-background-color;
    color: $form-text-color;
    border: 0.1rem solid $form-border-color;

    .Select-value-icon {
      border-right: 0.1rem solid $form-border-color;
    }
  }
}
