@import '../../_mixins/index';
@import '../../../utils/styles/variables';

.rf-tabs {
  &__wrapper {
    position: relative;

    &__list {
      display: flex;
      flex-flow: row;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .rf-tabs__scroll-left,
  .rf-tabs__scroll-right {
    z-index: 2;
    right: auto;
    border-radius: 4rem;
    opacity: 0.85;
  }

  .rf-tabs__scroll-right {
    right: 0;

    svg {
      left: 1.45rem;
      position: absolute;
    }
  }

  &__item {
    line-height: 3rem;
    flex: 1;
    border-bottom: 0.3rem solid transparent;
    cursor: pointer;
    text-align: center;
    z-index: 2;
    font-weight: bold;
    color: #9c9fa3;
    text-transform: uppercase;
    font-size: 1.3rem;
    padding: 0 1rem;

    &_active {
      border-bottom: 0.4rem #00afaa solid;
      color: #fff;
      cursor: default;
    }

    &:last-child {
      margin-right: 2rem;
    }
  }

  .rf-result-panel__tabs &__item {
    color: #6a6d74;
    &_active {
      color: #333942;
    }
  }

  &__content {
    flex: 1 100%;
    margin-top: 2.5rem;
    width: 100%;
    box-sizing: border-box;
  }

  .ui-sortable {
    .ui-sortable-item {
      background: #383c45;
    }
    .ui-sortable-dragging {
      background-color: #444851;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
      padding: 0 0.4rem 0.4rem 0.4rem;
      margin-left: -0.4rem;
    }
  }
}
