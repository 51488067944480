.rf-navbar {
  container-type: inline-size;
  width: 100%;
  height: 5.9rem;
  position: relative;
  background-color: #383b45;
  z-index: 6;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
  padding-left: 13.84rem;
  box-sizing: border-box;
  display: flex;

  &__logo {
    display: inline-block;
    width: 3.7rem;
    height: 3.3rem;
    left: 5.44rem;
    top: 1.7rem;
    position: absolute;
    background-image: url('./images/logo.svg?inline');
    background-size: 3.7rem 3.3rem;
    background-repeat: no-repeat;
    transition: transform 0.75s;
  }

  .rf-help-link {
    position: absolute;
    right: 4.3rem;
    top: 2.6rem;
    font-size: 1.8rem;
    font-weight: 300;
    text-align: left;
    color: #999b9f;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}

@container (width < 160rem) {
  .rf-search-bar {
    width: calc(100% - 105rem);
  }
}

@container (width < 155rem) {
  #react-select-2-placeholder {
    margin-top: -0.5rem;
    max-width: 40rem;
  }
}

@container (width < 134rem) {
  #react-select-2-placeholder {
    margin-top: 0rem;
    max-width: 42rem;
  }
  .rf-navbar {
    span {
      font-size: 1.6rem;
    }
    .rf-help-link {
      right: 1.3rem;
    }
    .rf-user {
      right: 0.8rem;
    }
    .rf-search-bar {
      left: -8rem;
      width: calc(100% - 74.5rem);
      min-width: 20.5rem;
    }
    .rf-settings {
      right: 6.4rem;
    }
    .rf-auto-refresh {
      right: 8.3rem;
      .rf-toggle-button {
        &__label {
          padding-right: 0.3rem;
        }
      }
    }
    .rf-auto-zoom {
      right: 24.5rem;
      .rf-toggle-button{
        &__label {
          padding-right: 0.3rem;
        }
      }
    }
    .rf-app-zoom {
      right: 40rem;
      &__caption {
        padding-right: 0.3rem;
      }
    }
    .rf-app-timezone {
      right: 56rem;
      width: 27.5rem;
      &__caption {
        padding-right: 0.3rem;
      }
    }
    &__logo {
      left: 1.44rem;
    }
  }
}

@container (width < 123rem) {
  #react-select-2-placeholder {
    margin-top: -0.5rem;
    max-width: 40rem;
  }
}

@container (width < 103rem) {
  #react-select-2-placeholder {
    margin-top: -0.2rem;
    font-size: 1.4rem;
    min-width: 16rem;
  }
}
