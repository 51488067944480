.rf-autocomplete {
  width: 100%;
  line-height: normal;

  &__menu-item {
    cursor: pointer;
    background: #fff;
    padding: 0.3rem 1rem;
    border-color: #aaa;
    border-width: 0 0.1rem 0.1rem 0.1rem;
    border-style: solid;
    font-size: 1.3rem;
    line-height: normal;
    text-align: left;

    &:hover,
    &_selected {
      background: #1e90ff;
      color: #fff;
    }
  }
}
