.rf-app-timezone {
  position: absolute;
  width: 30rem;
  right: 75rem;
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__caption {
    color: #9c9fa3;
    font-size: 1.8rem;
    padding-right: 1rem;
  }

  select {
    width: 13rem;
  }
}
