.rf-actions {
  cursor: pointer;

  &__btn {
    font-size: 3rem;
    padding: 1rem;
  }

  &__dropdown {
    position: fixed;
    top: 4.3rem;
    right: 1rem;
    font-size: 1.6rem;
    color: #babcc4;
    border-top: 0.1rem solid #48dad0;
    background-color: #4e515a;
    width: 17rem;
    z-index: 1;

    &_left {
      margin-left: -18rem;
    }

    &__item {
      padding: 1rem 3rem;
      border-bottom: 0.1rem solid black;

      &:hover {
        color: white;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
