.rf-layer-toggle {
  width: 5rem;
  height: 5rem;
  background-color: rgba(186, 188, 196, 0.7);
  cursor: pointer;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  border-radius: 0.5rem;
  z-index: 400;

  @media screen and (max-height: 58.5rem) {
    width: 3rem;
    height: 3rem;
  }

  &_active {
    background: #f2f2f2;
  }
  &_disabled {
    cursor: not-allowed;
  }

  &__label {
    color: white;
  }
}

.rf-main__content_history {
  .rf-layer-toggle {
    @media screen and (max-height: 77.5rem) {
      width: 3rem;
      height: 3rem;
    }
  }
}
