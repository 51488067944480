@import '../../utils/styles/variables';

.rf-result-error {
  margin: 1rem 0;
  border: 2px $error-color solid;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.8rem;

  &__message {
    color: $error-color;
  }
}
