@import '../textarea/textarea.scss';

.rf-expand-form-row {
  border-bottom: 0.1rem solid #c0c0c0;
  padding: 0.5rem 0;
  &__toggler {
    position: absolute;
    transform: translate(-0.6rem, 0);
    margin-top: 1.2rem;
    right: 0.5rem;

    &:after {
      color: rgb(115, 124, 137);
      position: absolute;
      transform: translate(-0.3rem, 0) rotate(-90deg);
      content: '‹';
      line-height: 0;
      font-size: 2.2rem;
    }
  }

  &__container {
    max-height: 0;
    overflow: hidden;

    .rf-radiogroup__field {
      width: 100%;
      .rf-radiooption {
        width: 50%;
        float: left;
      }
    }

    .rf-expand-form-row__label {
      &-text {
        margin-left: 1rem;
      }
    }
  }

  &__label {
    cursor: pointer;
    @include form-caption;
    text-transform: none;
    color: #fff;
    width: 100%;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    position: relative;
    &:after {
      content: '';
    }

    .rf-tooltip__children {
      margin-left: 0.3rem;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      svg {
        fill: #b9bbc4;
      }
    }
  }

  &_expanded > &__container {
    max-height: 20000rem; /* emulation of height: auto */
    overflow-y: hidden;
  }

  &_expanded > &__label &__toggler {
    &:after {
      content: '›';
    }
  }

  &.rf-grey-box {
    border-bottom-width: 0;
  }
}
