$left-sidebar-width: 46rem;
$left-sidebar-color: #383c45;
$right-sidebar-width: 40rem;
$icons-bar-width: 6rem;
$form-background-color: #f5f5f5;
$form-border-color: #d2d2d2;
$form-text-color: #696868;

$background-hover-color: #eee;

$close-button-background-color: $form-border-color;
$button-blue-color: #00c9ff;
$button-blue-hover-color: #00b4e5;

$error-color: #ce0202;

$very-light-grey: #f9f9f9;
$light-grey: #f2f2f2;
$dark-grey: #96989e;

$green-button-color: #00afaa;
$light-grey-button-color: $light-grey;
