.rf-form-row {
  margin: 0.8rem 0 0 0;
}

.rf-form-inline {
  display: flex;
  .rf-form-row {
    width: 50%;
    margin: 0.8rem 1.9rem 0 0;
    &:last-child {
      margin-right: 0;
    }
    .rf-input__caption,
    .rf-input__container {
      display: block;
      float: none;
      width: 100%;
    }
  }
}
