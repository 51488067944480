@import '../../../views/_mixins/index.scss';

.rf-transport-type-icon {
  @include maneuver-transport-icon;

  &_change {
    background-image: url('images/icon-change.png');
  }

  &_leave {
    background-image: url('images/icon-leave.png');
  }

  &_park {
    background-image: url('images/icon-park.png');
  }

  &_dialaRide {
    background-image: url('images/icon-dialaRide.png');
  }

  &_railMetro,
  &_subway,
  &_7 {
    background-image: url('images/icon-railMetro.png');
  }

  &_trainInterCity,
  &_intercityTrain,
  &_1 {
    background-image: url('images/icon-trainInterCity.png');
  }

  &_trainRegional,
  &_regionalTrain,
  &_3 {
    background-image: url('images/icon-trainRegional.png');
  }

  &_water,
  &_ferry,
  &_6 {
    background-image: url('images/icon-water.png');
  }

  &_enter {
    background-image: url('images/icon-enter.png');
  }

  &_busPublic,
  &_bus,
  &_5,
  &_privateBus,
  &_9,
  &_busRapid,
  &_12 {
    background-image: url('images/icon-busPublic.png');
  }

  &_railLight,
  &_tram {
    background-image: url('images/icon-railLight.png');
  }

  &_trainHighSpeed,
  &_highSpeedTrain,
  &_0,
  &_trainLongDistance {
    background-image: url('images/icon-trainHighSpeed.png');
  }

  &_trainUrban,
  &_interRegionalTrain,
  &_2,
  &_railRegional,
  &_cityTrain,
  &_4,
  &_railMetroRegional,
  &_lightRail,
  &_8,
  &_monorail,
  &_13 {
    background-image: url('images/icon-trainUrban.png');
  }

  &_bike,
  &_bicycle,
  &_17 {
    background-image: url('images/icon-bike.png');
  }

  &_bikeShare,
  &_bicycleShare,
  &_18 {
    background-image: url('images/icon-bikeShare.png');
  }

  &_walk,
  &_20 {
    background-image: url('images/icon-walk.png');
  }

  &_car,
  &_21 {
    background-image: url('images/icon-pnrCar.png');
  }

  &_carShare,
  &_22 {
    background-image: url('images/icon-carShare.png');
  }

  &_taxi,
  &_23 {
    background-image: url('images/icon-taxi.png');
  }

  &_kickScooter,
  &_kickScooterShare,
  &_25,
  &_26 {
    background-image: url('images/icon-kickScooter.svg');
  }

  &_scooter {
    background-image: url('images/scooter.svg?inline');
  }

  &_park,
  &_change,
  &_leave,
  &_enter {
    background-size: 2.3rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}
