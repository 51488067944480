.rf-input-search {
  padding-bottom: 2rem;
  position: relative;

  .rf-filter-input {
    .rf-input__caption {
      color: #b9bbc4;
      font-weight: bold;
    }
  }

  &__divider {
    height: 3px;
    width: calc(100% + 2rem);
    background-color: #b9bbc4;
    position: absolute;
    bottom: 0;
    left: -1rem;
  }
}
