@import '../textarea/textarea.scss';

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.rf-input {
  display: block;

  &__field {
    @include form-field;
    width: 100%;
    height: 100%;
    float: none;

    &_error,
    &_error:focus {
      box-shadow: 0rem 0rem 0 0.1rem #d5232f inset !important;
      border: 0.1rem solid #ce0202 !important;
    }

    @include placeholder {
      font-style: italic;
    }
  }

  &__container {
    height: 4rem;
    float: right;
    width: 100%;
    position: relative;
    display: flex;
  }

  &__caption {
    @include form-caption;

    .rf-tooltip__children {
      margin-left: 0.3rem;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      svg {
        fill: #b9bbc4;
      }
    }
  }

  &_compact &__caption {
    width: 40%;
    display: inline-block;
  }

  &_compact &__container {
    width: 60%;
    float: right;
    height: 3rem;
  }

  &__btn-copy {
    background: url('copy.svg?inline') #eee 50% 50% no-repeat;
    background-size: 2.4rem;
    min-width: 0;
    display: block;
    border: 0.1rem solid #d2d2d2;
    width: 2.6rem;
    border-left: 0;
    height: 100%;
    border-radius: 0 0.3rem 0.3rem 0;

    &:hover {
      background-image: url('copy_hover.svg?inline');
    }

    &_locked {
      background-image: url('copy_locked.svg?inline');
    }
  }

  &_readonly {
    pointer-events: none;
    .rf-input {
      &__container,
      &__field {
        height: 4rem;
        pointer-events: auto;
      }
      &__container {
        opacity: 0.5;
      }
    }
  }

  &_copyable {
    .rf-input {
      &__btn-copy {
        display: block;
      }

      &__field {
        width: calc(100% - 2.6rem);
        padding-right: 0.5rem;
      }
    }
  }

  .rf-copy-button {
    height: 100%;
  }
}
