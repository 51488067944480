.ui-sortable {
  display: block;
  position: relative;
  overflow: visible;
  user-select: none;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &-item {
    &.ui-sortable-dragging {
      position: absolute;
      z-index: 2;
    }
  }

  &-placeholder {
    display: none;

    &.visible {
      display: block;
      z-index: -1;
    }

    .rf-fieldset {
      background-color: #d4dce3;
    }
  }
}

.rf-multiple-points {
  margin-top: 1rem;
  position: relative;

  .rf-point-item {
    display: flex;
    margin-bottom: 1rem;

    &__drag {
      display: flex;
      position: absolute;
    }

    &__input {
      width: 100%;

      .rf-input__caption {
        padding-left: 3.4rem;
      }
    }
  }
}
