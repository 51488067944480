.rf-modal {
  &__actions {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    lui-button {
      display: flex;
    }
  }
}
