@import './settingsForm';

.rf-settings {
  display: inline-block;
  position: absolute;
  right: 11.4rem;
  text-align: right;
  line-height: 1rem;

  &__btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 2.6rem;
    width: 1.8rem;
    height: 1.9rem;
    background-image: url('./images/settings.svg?inline');
    background-size: 1.8rem 1.9rem;

    &:hover {
      background-image: url('./images/settings_hover.svg?inline');
    }

    &_active {
      background-image: url('./images/settings_active.svg?inline');
    }
  }

  .rf-settings-notification {
    position: absolute;
    top: 1.8rem;
    right: -0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: $control-color;
  }

  .rf-tabs__item:last-child {
    margin-right: 0;
  }
}
