.rf-ols-summary.rf-map-notices {
  border-radius: 0.5rem;
  position: fixed;
  background-color: #f5f5f5;
  z-index: 500;

  .rf-section-notice {
    margin-top: 0;
    padding: 1rem;
  }

  .rf-form-row {
    .rf-input__caption {
      color: #6a6d74;
    }
    .rf-input__field {
      background-color: transparent;
      color: #333942;
    }
    .rf-input__container {
      padding-left: 0;
    }
  }
}
