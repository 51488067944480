@mixin form-field {
  display: inline;
  border-radius: 0.2rem;
  font-size: 1.6rem;
  width: 100%;
  line-height: 2rem;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  word-wrap: break-word;

  height: 4rem;
  background-color: #272d37;
  color: #babcc4;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
}

/*TODO: Copypaste from form-caption. Need to implement compatible post-css plugin for mixin extending or move to node-sass*/
@mixin form-text {
  color: #696868;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
}

@mixin form-caption {
  color: #b9bbc4;
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  line-height: 3rem;
  text-align: left;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
}
