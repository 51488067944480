.rf-context-menu {
  position: fixed;
  z-index: 1001;
  border-radius: 0.2rem;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.5);
  background-color: #383c45;
  padding: 1rem 0;
  color: #fff;
  min-width: 22rem;
}
