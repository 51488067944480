.rf-columns {
  display: flex;
  flex-wrap: wrap;
  column-count: 2;

  & > * {
    width: calc(50% - 0.95rem);

    &:nth-child(2n) {
      margin-left: 1.9rem;
    }
  }
}
