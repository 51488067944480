.rf-rectangle-list__marker.leaflet-marker-icon {
  display: flex;
  background-color: black;
  border: 1px black solid;
  margin-top: 1px !important;
  margin-left: -12px !important;
  justify-content: center;
  line-height: 11px;
}

.rf-rectangle-list__marker {
  display: flex;
  background-color: white;
  border: 1px black solid;
  margin-top: 1px !important;
  margin-left: -12px !important;
  justify-content: center;
  line-height: 11px;
  width: 12px;
}

.rf-rectangle-list__marker.leaflet-marker-icon.black {
  background-color: black;
}