.rf-ols-consumption {
  border-bottom: none;

  .rf-optional {
    min-inline-size: auto;
  }
}

.rf-battery-fields {
  .rf-connector-types {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
