.rf-close-button {
  display: inline-block;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 3rem;
  vertical-align: top;
  cursor: pointer;

  svg {
    fill: #96989e;

    &:before {
      position: relative;
      top: -0.4rem;
      left: -0.4rem;
    }

    &:hover {
      fill: white;
    }

    &:active {
      fill: #00afaa;
    }
  }
}
