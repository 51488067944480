.rf-context-menu-item {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.13;
  padding-right: 1rem;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  padding-left: 1rem;

  &:hover {
    background: rgba(39, 49, 66, 0.9);
  }
}
