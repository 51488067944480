.rf-nav-tabs {
  background: #0f1520;
  height: 5rem;
  position: relative;

  .rf-nav-tabs__sortable {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 19.4rem);
    height: 5rem;
    margin-right: 7.9rem;
  }

  .ui-sortable:before,
  .ui-sortable:after {
    content: ' ';
    display: table;
  }

  .ui-sortable:after {
    clear: both;
  }

  .ui-sortable .ui-sortable-item.ui-sortable-dragging {
    position: absolute;
    z-index: 1688;
    width: auto !important;
    height: auto !important;
    -ms-transform: rotate(7deg); /* IE 9 */
    -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
    transform: rotate(7deg);

    .rf-nav-tabs__tab__inner {
      border-top-color: transparent;
    }
  }

  .ui-sortable .ui-sortable-placeholder {
    display: none;
  }

  .ui-sortable .ui-sortable-placeholder.visible {
    display: inline-block;
    z-index: -1;
  }

  &__tab,
  &__add-new-tab {
    display: inline-block;
  }

  &__add-new-tab,
  &__tab__inner,
  &__scroll-controls {
    height: 2.2rem;
    line-height: 2.2rem;
    padding: 0.9rem 1rem;
    border-top: 0.3rem solid #0f1520;
    margin: 0.3rem 0.4rem 0.4rem 0.4rem;
    vertical-align: top;
    font-size: 1.6rem;
  }

  &__add-new-tab {
    color: #fff;
    cursor: pointer;

    &__plus {
      color: #49dcd2;
      font-weight: 300;
      font-size: 2.5rem;
      height: 2.2rem;
      overflow: hidden;
      line-height: 2.2rem;
      max-height: 2.2rem;
      vertical-align: middle;
      margin-left: 1rem;
    }
  }

  &__tab {
    &__inner {
      box-sizing: content-box;
      background: #272d37;
      cursor: pointer;
      position: relative;
      padding: 0.5rem 1rem 0.4rem 4rem;
      margin: 0.7rem 0.4rem 0.8rem 0.4rem;
    }

    &__checkbox,
    .rf-close-button {
      position: relative;
      top: 2px;
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    .fa-ellipsis-v {
      color: #96989e;
      padding: 0 0.5rem;
    }

    .rf-checkbox {
      height: 1.6rem;

      &__field {
        height: 1.6rem;
        float: left;
      }
    }

    &__is-active {
      width: 2.5rem;
      position: absolute;
      left: 0.5rem;
      top: 0;
      bottom: 0;
      margin: auto;

      svg {
        width: 100%;
        height: 100%;
        fill: #62666d;
      }
    }

    &__is-active,
    .fa-ellipsis-v,
    .rf-close-button {
      &:hover {
        svg {
          fill: white !important;
        }
      }

      &:active {
        color: #00afaa !important;
      }
    }

    &__caption {
      width: auto;
      line-height: inherit;
      margin-right: 0.5rem;
      color: #96989e;
      position: relative;
      height: 2.2rem;
      display: inline-block;

      &__label {
        display: inline-block;
        min-width: 5.4rem;
        text-align: center;
      }

      &__actions {
        display: inline-block;
        position: relative;

        &__dropdown {
          position: fixed;
          z-index: 6;
          border-top: 0.1rem solid #48dad0;
          background-color: #4e515a;
          color: #babcc4;
          font-size: 1.6rem;

          &__item {
            padding: 1rem 3rem;
            border-bottom: 0.1rem solid black;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              color: white;
            }
          }
        }

        &_active {
          .fa-ellipsis-v {
            color: #48dad0 !important;
          }
        }
      }
    }

    &_selected {
      .rf-nav-tabs__tab {
        &__inner {
          background: #383c45;
          padding: 0.9rem 1rem 0.9rem 4rem;
          margin: 0.3rem 0.4rem 0.4rem 0.4rem;
        }

        &__is-active {
          height: 4rem;
        }

        &__caption {
          font-weight: bold;
        }
      }
    }

    &__title-input {
      font-size: 1.6rem;
      input {
        background-color: transparent;
        border: none;
        color: white;
        margin-right: 0.5rem;
        min-width: 5.4rem;
      }
    }

    .rf-loader {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      background-size: 2.4rem;
      left: 1rem;
      top: 50%;
      margin-top: -1.2rem;
    }
  }

  &__tab:last-child {
    margin-right: 7.8rem;
  }

  &__add-new-tab__plus {
    cursor: pointer;
  }

  &__scroll-controls {
    user-select: none;
    font-size: 2.9rem;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    right: 7.5rem;
    top: 0;
    background: #272d37;
    color: #999ba0;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 1);
    padding: 0;
    height: 4.3rem;

    &__left,
    &__right {
      width: 3.8rem;
      height: 4rem;
      line-height: 2.2rem;
      display: inline-block;
      cursor: pointer;
      background-size: cover;

      &_disabled {
        cursor: auto;
      }
    }
    &__left {
      background: url('./images/prev.svg?inline') no-repeat center center;
      background-size: cover;

      &:hover {
        background-image: url('./images/prev_hover.svg?inline');
      }

      &:active {
        background-image: url('./images/prev_active.svg?inline');
      }

      &_disabled {
        background-image: url('./images/prev_locked.svg?inline');

        &:hover {
          background-image: url('./images/prev_locked.svg?inline');
        }
      }
    }
    &__right {
      background: url('./images/next.svg?inline') no-repeat center center;
      background-size: cover;

      &:hover {
        background-image: url('./images/next_hover.svg?inline');
      }

      &:active {
        background-image: url('./images/next_active.svg?inline');
      }
      &_disabled {
        background-image: url('./images/next_locked.svg?inline');
        &:hover {
          background-image: url('./images/next_locked.svg?inline');
        }
      }
    }
  }
}
