.rf-distance-measurement__marker {
  background-color: white;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 12px;
  height: 12px;
  color: white;
  border: 2px black solid;
  border-radius: 50%;
}