@import '../textarea/textarea.scss';

.rf-select {
  &__field {
    @include form-field;

    padding: 0.4rem 1rem;
    outline: none;
    display: inline-block;
    appearance: none;
    -webkit-padding-before: 0.8rem;
    -webkit-padding-after: 0.8rem;
    background-color: rgb(61, 66, 75);
    border: 1px solid #222;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #00c9ff inset;
      border: 0.1rem solid #00c9ff;
    }

    option {
      padding: 0.8rem 0.5rem;
    }

    &_error {
      box-shadow: 0rem 0rem 0 0.1rem #d5232f inset !important;
      border: 0.1rem solid #ce0202 !important;
    }
  }

  &__wrapper {
    position: relative;

    &:after {
      content: '\2039';
      font-size: 1.6rem;
      color: #babcc4;
      transform: rotate(-90deg);
      right: 1.2rem;
      top: -0.5rem;
      position: absolute;
      pointer-events: none;
    }
  }

  &__caption {
    @include form-caption;
    .rf-tooltip__children {
      margin-left: 0.3rem;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      svg {
        fill: #b9bbc4;
      }
    }
  }
}

.rf-select-even-fields {
  .rf-select__caption,
  .rf-select__field {
    width: 50%;
  }
}
