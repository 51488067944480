.rf-app-zoom {
  position: absolute;
  width: 18rem;
  right: 55rem;
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__caption {
    color: #9c9fa3;
    font-size: 1.8rem;
    padding-right: 1rem;
  }

  select {
    width: 10rem;
  }
}
