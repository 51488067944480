.rf-reverse {
  position: absolute;
  right: 1rem;
  padding: 1rem;
  cursor: pointer;

  svg {
    fill: #babcc4;
  }

  &:hover {
    svg {
      fill: #ffffff;
    }
  }
}
