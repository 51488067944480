@mixin background-image-retina(
  $file,
  $width: 100%,
  $height: $width,
  $type: 'png'
) {
  background-image: url($file + '.' + $type);
  background-size: $width $height;
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file + '@2x.' + $type);
    }
  }
}

.rf-new-tab {
  &__description {
    color: #babcc4;
    font-size: 1.6rem;
    margin: 1.4rem 1.9rem;
  }

  &__group {
    padding: 0;
    margin: 0;
    border: 0;
    &__item {
      margin: 1.4rem 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.2s ease-out;
      height: 7.7rem;
      position: relative;

      @include background-image-retina(
        './images/calculate_route',
        44rem,
        7.7rem,
        'png'
      );

      &:hover {
        @include background-image-retina(
          './images/calculate_route_hover',
          44rem,
          7.7rem,
          'png'
        );
      }

      &__label {
        opacity: 0.5;
        border-radius: 0.4rem;
        background-color: #272d37;
        font-size: 2.4rem;
        color: #ffffff;
        margin: 0 1.6rem;
        padding: 0.9rem;
      }

      &__actions-btn {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 3rem;
        padding: 1rem;
      }

      &__actions-dropdown {
        position: fixed;
        right: 1rem;
        top: 4.3rem;
        font-size: 1.6rem;
        color: #babcc4;
        border-top: 0.1rem solid #48dad0;
        background-color: #4e515a;
        width: 17rem;
        z-index: 1;

        &__item {
          padding: 1rem 3rem;
          border-bottom: 0.1rem solid black;

          &:hover {
            color: white;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .rf-fieldset {
    margin: 1.5rem 0.9rem 0 0.9rem;
  }
}
