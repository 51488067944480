.rf-auto-zoom {
  position: absolute;
  right: 35.2rem;
  margin-top: 2.5rem;
  padding-right: 1rem;
  display: flex;

  &__label {
    color: #9c9fa3;
    font-size: 1.8rem;
    padding-right: 1rem;
  }

  .rf-toggle-button {
    display: flex;
  }
}
