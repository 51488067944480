@import '../../utils/styles/variables';

.rf-form-view-error {
  margin: 1rem 0;
  border: 2px $error-color solid;
  border-radius: 0.5rem;
  padding: 1rem;

  &__message {
    color: $error-color;
  }
}
