.rf-speed-group {
  border: 0.1rem solid transparent;

  &_error .rf-speed-group__rows {
    border: 0.2rem solid red;
  }

  .rf-tabs-control {
    margin-bottom: 2rem;
  }

  &__title {
    & > div {
      float: left;
      font-size: 1.3rem;
      letter-spacing: 0.03rem;
      color: #b9bbc4;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 16.6rem;
    }
  }
  &__row {
    display: flex;
    width: 100%;
    &:first-child {
      height: 3rem;
    }
    .rf-input {
      padding: 1rem 2rem 1rem 0;

      &__field,
      &__caption,
      &__container {
        width: 100%;
        box-sizing: border-box;
      }
      &__field {
        min-height: 0;
        width: 14.6rem;
      }
    }
  }
  &__graph {
    .rf-chart {
      position: relative;
      width: 38rem;

      .c3-xgrid,
      .c3-ygrid {
        stroke: #6b6e74;
        stroke-dasharray: 0;
      }

      .c3-line {
        stroke-width: 0.4rem;
      }

      .domain {
        stroke: #6b6e74;
      }

      .tick {
        line {
          stroke: #6b6e74;
        }
        text {
          font-size: 1.1rem;
          text-align: center;
          stroke: #b9bbc4;
        }
      }
    }
  }
  .rf-close-button {
    align-self: flex-start;
  }
  .rf-add-button {
    margin-top: 0.3rem;
    width: 12.6rem;
  }
}
