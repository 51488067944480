.rf-result-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;

  .rf-tabs__item {
    color: #6a6d74;
    white-space: nowrap;

    &_active {
      color: #333942;
    }
  }
}
