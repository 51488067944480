.rf-extra-params-delimiter {
  width: calc(51% - 1.95rem);
}

.rf-extra-params-value {
  width: calc(38%);
}

.rf-extra-params-place-holder {
  width: 1px;
}

.rf-extra-params-close-button {
  margin-top: 3.2rem;
  width: 2.2rem;
}