.rf-tabs-control {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  height: 4rem;

  li {
    height: 100%;
    position: relative;
    flex: 1;

    &:first-child label {
      border-radius: 0.2rem 0 0 0.2rem;
    }

    &:last-child label {
      border-radius: 0 0.2rem 0.2rem 0;
    }
  }

  &__two_rows {
    li {
      width: 50%;
    }
  }

  input[type='radio'] {
    opacity: 0.011;
    z-index: 100;
  }

  input[type='radio']:checked + label {
    background: #00afaa;
  }

  label {
    border: 0.1rem solid #00afaa;
    text-align: center;
    cursor: pointer;
    z-index: 90;
    font-size: 1.3rem;
    color: #ffffff;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    position: absolute;
  }
}
