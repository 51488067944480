.rf-toggle-button {
  &__label {
    color: #9c9fa3;
    font-size: 1.8rem;
    padding-right: 1rem;
  }

  .react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;

    &:hover:not(&--disabled) &-track {
      background-color: #000;
    }

    &:active:not(&--disabled) &-thumb {
      box-shadow: 0rem 0rem 0.5rem 0.5rem #0099e0;
    }

    &-screenreader-only {
      border: 0;
      clip: rect(0 0 0 0);
      height: 0.1rem;
      margin: -0.1rem;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0.1rem;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
      transition: opacity 0.25s;
    }

    &-track {
      width: 5rem;
      height: 2.4rem;
      padding: 0;
      border-radius: 3rem;
      background-color: #4d4d4d;
      transition: all 0.2s ease;
    }

    &--checked {
      .react-toggle-track {
        background-color: #00afaa;
      }
      .react-toggle-track-check {
        opacity: 1;
        transition: opacity 0.25s ease;
      }
      .react-toggle-track-x {
        opacity: 0;
      }
      .react-toggle-thumb {
        left: 2.7rem;
        border-color: #00afaa;
      }
      &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #00afaa;
      }
    }

    &-track-check {
      position: absolute;
      width: 1.4rem;
      height: 1rem;
      top: 0rem;
      bottom: 0rem;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      left: 0.8rem;
      opacity: 0;
      transition: opacity 0.25s ease;
    }

    &-track-x {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 0rem;
      bottom: 0rem;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      right: 1rem;
      opacity: 1;
      transition: opacity 0.25s ease;
    }

    &-thumb {
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
      width: 2.2rem;
      height: 2.2rem;
      border: 0.1rem solid #4d4d4d;
      border-radius: 50%;
      background-color: #fafafa;
      box-sizing: border-box;
      transition: all 0.25s ease;
    }

    &--focus &-thumb {
      box-shadow: 0rem 0rem 0.2rem 0.3rem #0099e0;
    }
  }
}
