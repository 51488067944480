.rf-inspect-segment-result {
  .rf-tabs__item_active {
    color: #333942;
  }
  .rf-tree {
    margin-left: 2rem;
  }
  .rf-tree-attributes {
    margin-left: 1rem;
  }
}
