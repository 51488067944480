.rf-new-version {
  background-color: #ffbd09;
  border-radius: 0.2rem;
  padding: 1rem;
  margin: 1rem 0;

  &__text {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
