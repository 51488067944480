@import '../../../../views/_mixins/index';

.rf-routedate-field {
  margin-top: 1rem;

  &__value {
    margin-top: 2rem;
  }

  &__caption {
    @include form-caption;
  }

  .rf-select__field {
    width: 100%;
    float: none;
  }

  h3 {
    padding-bottom: 1rem;
  }
}
