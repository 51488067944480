.rf-avoid, .rf-exclude {
  &__area {
    position: relative;
    // margin-top: 1rem;

    .rf-close-button {
      right: 0;
      top: 0;
    }

    .rf-avoid-close-button {
      position: absolute;
      // top: -6px;
      right: 15px;
    }

    .rf-exclude-close-button {
      position: absolute;
      // top: -6px;
      right: 15px;
    }
  }
}

.rf-without-box {
  border: 0px;
}