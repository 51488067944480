.rf-filter-input {
  position: relative;
  margin-bottom: 1rem;

  .rf-input__caption {
    color: #272d37;
    font-weight: bold;
  }

  ul {
    position: absolute;
    top: 7rem;
    background-color: #272d37;
    color: #babcc4;
    z-index: 2;
    font-size: 2rem;
    max-height: 30rem;
    overflow-y: auto;

    li {
      padding: 0.5rem 1rem;
      border-bottom: solid 1px;
      border-top: solid 1px;

      &:hover,
      &.rf-filter-input__item_hovered {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
}
