.rf-distance-measurement-form-view {
  display: flex;
  flex-direction: column;

  .rf-button {
    margin-top: 1rem;
    margin-left: 0;
    justify-self: flex-end;
  }

  .distance-measurement-on {
    background-color: #00AAD9;
  }
}
