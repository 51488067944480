@import '../../../utils/styles/variables';
@import '../../_mixins/general';

.rf-result-panel {
  float: none;
  position: absolute;
  right: -$right-sidebar-width;
  width: $right-sidebar-width;
  top: 0;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  transition: right ease 0.5s;

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $dark-grey;
    margin-top: -1.5rem;
    margin-left: -1rem;
    opacity: 0.7;
    z-index: 7;

    .rf-loader {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__tabs {
    margin: 1.5rem 0 0 0;
    &-gap {
      padding: 0 1.9rem;
      font-size: 1.2rem;
    }

    &__tlv-response {
      word-break: break-all;
      font-size: 1.2rem;
    }
  }

  &__color {
    @include color;
  }

  &__toggler {
    position: absolute;
    top: 1.5rem;
    left: -3.3rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    background-color: #f5f5f5;
    text-align: center;
    box-shadow: -0.1rem 0 0.1rem 0rem rgba(0, 0, 0, 0.4);
    font-size: 1.2rem;
    min-width: 4rem;
    color: #666;
    box-sizing: content-box;
    display: none;

    transform: translate(-0.6rem, 0);

    &:after {
      content: '';
      position: absolute;
      top: 1.2rem;
      left: 1.5rem;
      width: 1rem;
      height: 1.6rem;
      background-image: url('./images/show_response.svg?inline');
      background-size: 1rem 1.6rem;
    }

    &:hover {
      &:after {
        background-image: url('./images/show_response_hover.svg?inline');
      }
    }

    &_visible {
      display: block;
    }
  }

  &_fadeout {
    right: 0;
  }

  &_fadeout &__toggler {
    &:after {
      background-image: url('./images/hide_response.svg?inline');
      background-size: 1rem 1.6rem;
    }

    &:hover {
      &:after {
        background-image: url('./images/hide_response_hover.svg?inline');
      }
    }
  }

  &__centered {
    text-align: center;
  }

  &__route-id {
    .rf-input {
      &__container {
        height: 4rem;
      }
      &__field {
        word-wrap: normal;
        overflow-x: auto;
      }
    }
  }

  .rf-expand-form-row {
    &__label-text {
      font-size: 1.6rem;
      color: #6a6d74;
      font-weight: 300;
    }
    &__label {
      margin: 0 1.9rem;
      width: auto;
    }
  }

  &__inspect-link {
    position: relative;
    border-top: 0.8rem rgba(158, 161, 165, 0.25) solid;
    border-bottom: 0;
    padding: 1.4rem 0 0 0;
    margin-top: 1.9rem;
    width: 100%;
    .rf-close-button {
      position: absolute;
      right: 1.2rem;
      top: 2.1rem;
      color: #6a6d74;
      font-weight: bold;
    }
  }

  &__ror-module {
    border-top: 0.1rem solid #bbb;
    margin-top: 0.5rem;
  }

  .rf-expand-form-row {
    border-bottom: 0;
  }

  .rf-form-row {
    .rf-input {
      &__caption {
        font-size: 1.3rem;
        letter-spacing: 0.03rem;
        text-align: left;
        color: #6a6d74;
        text-transform: uppercase;
      }

      &__field {
        font-size: 1.6rem;
        line-height: 1.25;
        text-align: left;
        color: #333942;
        background: transparent;
        border: 0;
      }
    }
  }

  .rf-sidebar__inner {
    display: flex;
    flex-direction: column;
  }

  .rf-sidebar__error .rf-input_readonly {
    .rf-input__container,
    .rf-input__field {
      height: auto;
    }
  }
}
