.rf-api-url-input {
  display: flex;
  flex: 1;

  .rf-copy-button {
    margin: 0 0.5rem 0 1.5rem;
  }

  .rf-input {
    &__container {
      flex: 1;
      height: 4rem;
      border-radius: 0.2rem;
    }

    &__field {
      box-shadow: none;
      border-radius: 0.2rem 0 0 0.2rem;
      font-size: 1.6rem;
      line-height: 4rem;
      border: 0;

      background-color: #e5e7ea;
      color: #6a6d74;
    }

    &__caption {
      overflow: hidden;
      line-height: 4rem;
      display: inline-block;
      font-size: 1.3rem;
      margin-right: 1.5rem;
      color: #96989e;
      font-weight: 400;
      text-transform: uppercase;
      width: auto;
    }
  }
}

.rf-api-url-copy-btn {
  background-color: transparent;
  border: 0;
  height: 4rem;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
}
