.rf-toggler {
  position: absolute;
  top: 1.5rem;
  right: -4rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: #383c45;
  box-shadow: -0.1rem 0 0.1rem 0rem rgba(0, 0, 0, 0.4);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    content: '';
    width: 1.5rem;
    height: 2rem;
    fill: #9c9fa3;
  }
}
