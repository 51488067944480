.rf-api-url-post-data {
  margin-left: 2rem;
  &__title {
    display: flex;
    font-size: 2rem;
    align-items: center;

    .rf-copy-button {
      margin-left: 1rem;
    }

    .rf-paste-btn {
      margin-left: 1rem;
    }
  }

  &__textarea {
    font-size: 1.8rem;
    width: calc(100% - 2rem);
    margin: 1rem 2rem 3rem 0;
    padding: 1rem;
    outline: none;

    &_error {
      border: 2px solid red;
      border-radius: 1rem;
    }
  }

  .rf-tree {
    margin-left: 1rem;
    font-size: 1.8rem;
  }

  .rf-post-data__object {
    &::before {
      content: '{';
    }

    &::after {
      content: '}';
    }
  }

  &__action-btns {
    margin-bottom: 1rem;
  }
}
