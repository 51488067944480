@import '../../../_mixins/index';

.rf-datetime {
  display: flex;
  align-items: center;

  .rf-input__container {
    float: none;
    width: 100%;
  }
  &__caption {
    @include form-caption;
  }

  &__field {
    .form-control {
      @include form-field;
      width: 100%;
    }

    .rdtPicker {
      position: absolute;
      background-color: transparent;
      border: none;
      font-size: 1.8rem;

      .rf-datetime__picker {
        position: fixed;
        background: white;
        padding: 1rem;
      }
    }
  }

  &__calendar-btn {
    font-size: 0;
    cursor: pointer;

    .rf-datetime-icon {
      display: inline-block;
      background: #272d37 url(./images/Time.svg?inline) no-repeat center center;
      background-size: 7rem;
      border-right: 0.1rem solid #131923;
      width: 4rem;
      height: 4rem;

      &:hover {
        background-image: url(./images/Time_hover.svg?inline);
      }

      &:active {
        background-image: url(./images/Time_active.svg?inline);
      }
    }

    &_readonly {
      cursor: not-allowed;

      .rf-datetime-icon {
        opacity: 0.5;
        border-color: rgba(19, 25, 35, 0.5);
      }
    }
  }
}
