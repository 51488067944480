.rf-history {
  position: relative;
  background-color: #fff;
  z-index: 5;

  .rf-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -1.6rem;
    margin-top: -1.6rem;
  }

  &__overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    &_visible {
      display: block;
    }
  }
}
