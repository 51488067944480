.rf-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 4rem;
  line-height: 4rem;
  position: relative;

  &_disabled {
    cursor: not-allowed;

    .rf-checkbox__image {
      cursor: not-allowed;
    }
  }
}
