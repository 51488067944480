.rf-trace {
  .rf-trace-item {
    display: flex;
    position: relative;

    lui-checkbox {
      align-self: flex-end;
    }

    .rf-input {
      flex-grow: 1;
    }

    .rf-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .rf-button {
    margin-top: 1rem;
  }
}
