.rf-ols-summary {
  .rf-expand-form-row {
    .rf-expand-form-row__container {
      margin-left: 2rem;

      .rf-form-row {
        margin-left: 1rem;

        &.rf-section-notice {
          margin-left: 0;
        }
      }
    }
  }

  &__total {
    background: #e8e8e8;
  }

  .rf-section-notice {
    border-radius: 0.5rem;
    border: #ffa500 1px solid;
    background-color: rgba($color: #ffa500, $alpha: 0.5);
    margin-left: -1rem;

    .rf-input_readonly {
      cursor: pointer;
    }

    h5 {
      font-size: 1.8rem;
      margin: 1rem;
    }

    .rf-form-row {
      margin-top: 0;
    }

    .rf-input__container {
      height: auto;
    }

    .rf-section-notice__item {
      border-top: 1px #ffa500 solid;
      margin-bottom: 2rem;
      cursor: pointer;

      &:first-child {
        border: none;
      }

      .rf-form-row {
        margin-left: 0;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      lui-button {
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100%;
      }
    }
  }

  .rf-global-notice {
    margin-left: 0;
    cursor: default;

    .rf-input_readonly {
      cursor: default;
    }

    .rf-form-row {
      margin-left: 1rem;

      .rf-input__container {
        padding-left: 1rem;
      }
    }
  }
}

