@import '../../_mixins/index';
@import '../../_mixins/general';

$background-color: #383c45;
$caption-color: #b9bbc4;
$field-bg-color: #272d37;
$field-color: #babcc4;
$control-color: #48dad0;

.rf-settings-form {
  width: 40rem;
  background-color: $background-color;
  text-align: left;
  margin: 6.3rem -11rem 0 0;
  position: relative;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.2);
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 0 1.5rem 1.5rem 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 7.8rem);

  &__environment {
    color: #999b9f;
    position: relative;

    .rf-actions {
      position: absolute;
      right: 0;
      top: 2.5rem;
    }

    .rf-select__field {
      width: 90%;
    }
  }

  &__action-btn-group {
    display: flex;
    margin-top: 2rem;

    lui-button {
      width: 100%;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
    margin: 16px 0;
  }

  &__color {
    @include color;
  }

  .rf-select {
    &__caption {
      font-size: 1.3rem;
      color: $caption-color;
      letter-spacing: 0.03rem;
      text-transform: uppercase;
    }

    &__field {
      float: none;
      height: 4rem;
      border-radius: 0.2rem;
      background-color: $field-bg-color;
      font-size: 1.6rem;
      color: $field-color;
      border: 0;
    }
  }

  .rf-input {
    &__caption {
      color: $caption-color;
      letter-spacing: 0.03rem;
      font-size: 1.3rem;
      text-transform: uppercase;
    }
    &__field {
      background-color: transparent;
      color: $field-color;
      border: 0;
      padding: 0.4rem 0;
      font-size: 1.6rem;
    }
  }

  .rf-app-id-code-form .rf-input {
    overflow: hidden;
    margin-bottom: 1rem;
    &__caption,
    &__container {
      width: 100%;
      height: auto;
    }
    &__field {
      background-color: $field-bg-color;
      height: 4rem;
      padding: 0;
    }
  }

  .rf-token-form .rf-input {
    display: flex;
    align-items: center;

    &__container {
      height: auto;
    }
  }

  .rf-radiogroup {
    lui-radiobutton-group {
      width: 60%;
      float: right;
    }
  }

  .rf-cancel-button,
  .rf-save-button,
  .rf-settings-form__warning-btn {
    height: 4rem;
    border-radius: 0.2rem;
    font-size: 1.3rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0 2rem;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .rf-cancel-button,
  .rf-settings-form__warning-btn {
    background: transparent;
    border: solid 0.1rem $control-color;
    color: $control-color;
  }

  .rf-save-button {
    background: $control-color;
    border: solid 0.1rem $control-color;
    color: $background-color;
  }

  hr {
    border-width: 0.1rem;
    border-bottom: 0;
    border-color: $caption-color;
    margin: 1.5rem 0 2.5rem 0;
  }

  &_fadein {
    opacity: 1;
    display: block;
  }

  &__warning {
    width: 100%;
    text-align: center;

    margin-bottom: 2rem;
    &-text {
      @include form-text;
      color: darkred;
      border: 0.1rem solid darkred;
      padding: 0 0.5rem;
    }

    &-btn {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  &__action-btns {
    float: right;

    &__cancel-btn {
      background-color: rgba(7, 16, 40, 0.7);
      margin-right: 1.5rem;

      &:hover {
        background-color: rgba(7, 16, 40, 0.5);
      }

      &:disabled {
        background-color: #999;
        cursor: not-allowed;
      }
    }
  }

  .rf-token-control {
    display: flex;

    .rf-input {
      width: 100%;

      &__field {
        width: calc(100% - 4rem);
      }
    }

    .rf-button {
      min-width: 4rem;
      height: 4rem;
      line-height: 3rem;
      font-size: 2rem;
      background-color: $control-color;
      color: $background-color;
      padding: 0;
      position: absolute;
      right: 0;
      margin: 0.4rem 1.6rem 0.4rem 0;
    }
  }

  .rf-input_readonly .rf-input__field {
    white-space: nowrap;
    overflow: auto;
  }
}
