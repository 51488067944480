@mixin form-field {
  display: inline;
  border-radius: 0.2rem;
  font-size: 1.6rem;
  width: 100%;
  line-height: 2rem;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  word-wrap: break-word;

  height: 4rem;
  background-color: #272d37;
  color: #babcc4;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
}

@mixin form-caption {
  color: #b9bbc4;
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  line-height: 3rem;
  text-align: left;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
}

.rf-textarea {
  display: block;

  &__field {
    @include form-field;
    resize: vertical;
    height: 6.8rem;

    &_error,
    &_error:focus {
      box-shadow: 0rem 0rem 0 0.1rem #d5232f inset !important;
      border: 0.1rem solid #ce0202 !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #00c9ff inset;
      border: 0.1rem solid #00c9ff;
    }
  }

  &__caption {
    @include form-caption;
  }
}
