.rf-result-tabs.rf-tabs {
  .rf-chart {
    position: relative;
    width: 38rem;
    left: -2.2rem;

    .c3-xgrid,
    .c3-ygrid {
      stroke: #dedede;
      stroke-dasharray: 0;
    }

    .domain {
      display: none;
    }

    .tick {
      line {
        stroke: #6b6e74;
      }
      text {
        font-size: 1.1rem;
        text-align: center;
        fill: #6a6d74;
      }
    }

    .c3-circle._expanded_ {
      stroke-width: 0.2rem;
      stroke: #3a7bdb;
      fill: #48dad0 !important;
    }

    .c3-axis-y-label,
    .c3-axis-y2-label,
    .c3-axis-x-label {
      fill: #000;
      font-size: 1.1rem;
      text-transform: uppercase;
    }

    .c3-tooltip-name--charging {
      display: none;
    }

    .c3-chart-lines path {
      opacity: 0.5 !important;
    }

    .color-grid {
      &-maxcharge {
        & > line {
          stroke: #000;
        }

        & > text {
          fill: #000;
        }
      }

      &-minchargeatstop {
        & > line {
          stroke: #f07d02;
        }

        & > text {
          fill: #f07d02;
        }
      }

      &-chargingstopdeparturecharge {
        & > line {
          stroke: green;
        }

        & > text {
          fill: green;
        }
      }
    }

    .c3-texts-energy text {
      fill: black !important;

      &:nth-child(even) {
        transform: translateY(1.8rem);
      }
      &:first-child {
        transform: translateX(1rem);
      }
      &:last-child {
        transform: translateX(-1rem) translateY(1.8rem);
      }
    }
  }

  .rf-waipoints-line {
    position: relative;
    text-align: center;
    font-size: 1.4rem;
  }

  .rf-checkbox-list__item {
    --lui-text-color: #696868;
  }
}

.rf-profiles {
  &__maximized {
    position: fixed;
    top: 0;
    left: 0;
    background: #f5f5f5;
    z-index: 7;
    width: 100%;
    height: 100vh;
    padding: 0 2rem 5rem 2rem;
  }

  .rf-expand-form-row {
    width: 35rem;
  }

  .rf-maximize,
  .rf-minimize {
    position: absolute;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    cursor: pointer;
  }

  .rf-maximize {
    background: url('./icons/maximize.svg') no-repeat center center;
  }

  .rf-minimize {
    background: url('./icons/minimize.svg') no-repeat center center;
  }

  .rf-routing_speed_units select{
    background: transparent!important;
    border: 1px rgba(35,52,89,.35) solid;
    color: #6a6d74;
  }
}
