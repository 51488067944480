@import '../../utils/styles/variables';

.rf-params-mapping {
  position: absolute;
  width: $left-sidebar-width;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .rf-toggle-button {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;

    .rf-toggle-button__label {
      text-align: center;
      font-size: 2.5rem;
    }

    .react-toggle {
      width: 5rem;
      margin-top: 1rem;
    }
  }
}
